import { IKDataEntity } from 'metaflow-js'
import hillo from 'hillo'
import WeekDays from '../common/WeekDays'

const entity = {
  id: {
    type: IKDataEntity.Types.Integer,
    displayName: 'id',
    tableConfig: {
      align: 'start',
    },
    formConfig: {
      disableNew: true,
      disableEdit: true,
      requiredNew: false,
    },
  },
  weekday: {
    type: IKDataEntity.Types.Option,
    formConfig: {
      type: {
        selectItems: WeekDays.getList(),
        multiple: true,
        disableEdit: true,
        itemText: 'name',
      },
    },
  },
  startTime: {
    type: IKDataEntity.Types.Time,
  },
  endTime: {
    type: IKDataEntity.Types.Time,
  },
}
const config = {
  add: function (item) {
    // item.weekday = item.weekday.join(',')
    return hillo.post('Takeaway.php?op=addOpeningTime', item, {
      showLoading: true,
    })
  },
  edit: function (item) {
    // item.weekday = item.weekday.join(',')
    return hillo.postWithUploadFile('Takeaway.php?op=updateOpeningTime', item, {
      showLoading: true,
    })
  },
  remove: function (id) {
    return hillo.post('Takeaway.php?op=deleteOpeningTime', { id: id })
  },
  load: async function (filter) {
    return (await hillo.get('Takeaway.php?op=showAllOpeningTime', { ...filter }))
      .content
  },
}
export default IKDataEntity.ModelFactory(entity, config)
